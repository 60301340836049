
.designTempContentContainer {
    padding-left: 40px;
    padding-right: 100px;
    padding-top: 62px;
}

.borderLine {
    border: 1px solid #000000;
    margin-right: 56px;
    margin-left: 56px;
    margin-bottom: 35px;
    margin-top: 35px;
}

@media only screen and (max-width: 1200px) {
    .designTempContentContainer {
        padding-left: 16px;
        padding-right: 30px;
        padding-top: 30px;
    }
}
