.about-us-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/general/aboutUsBackground.png");
    width: 100%;
    height: 415px;
    background-size: 100% 100%;
}
.about-us-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/general/aboutUsBackground.png");
    width: 100%;
    height: 415px;
    background-size: 100% 100%;
}

@media only screen and (max-width: 768px) {
    .about-us-banner-bg{
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/general/aboutUsBackground_mobile.png");
        width: 100%;
        height: 182px;
        background-size: 100% 100%;
    }
    .about-us-banner-bg-prod{
        background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/general/aboutUsBackground_mobile.png");
        width: 100%;
        height: 182px;
        background-size: 100% 100%;
    }
}
