/*.BannerBg {*/
/*    !*background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/homepage/homepageBanner.jpg");*!*/
/*    background-image: url("../assets/img/backgroundImgNew.jpg");*/
/*    !*width: 100%;*!*/
/*    !*background-size: 100% 100%;*!*/
/*    min-height: auto;*/
/*    position: relative;*/
/*    background-position: center;*/
/*    background-size: cover;*/
/*    background-color: #1a1a1a;*/
/*}*/

.BannerBg {
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/homepage/homepageBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6

}

.BannerBg-prod {
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/homepage/homepageBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6

}

.emailTempBorder {
    padding-right: 56px;
    padding-left: 56px;
    border-bottom: 1px solid #0B407D;
}

.emailCampPanel {
    background: #FFFFFF;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    min-height: 327px;
    height: 100%;
}

.emailMarketingContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    justify-content: space-between;
    overflow: hidden;
}

.emailMarketingContainer > div {
    padding-top: 40px;
    align-items: center;
    background-color: #fff;
    display: flex;
    flex: 1 0 21%;
    flex-direction: column;
    gap: 18px;
    /*height: 124px;*/
    justify-content: center;
}

.emailCampPanelHeader {
    border-radius: 4px;
    color: #1E1E1E;
}

.email-marketing-block {
    flex: 1 1 10%;
    gap: 20px;
}

/*@media only screen and (min-width: 768px) and (max-width: 992px) {*/
/*    .BannerBg {*/
/*        !*background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/homepage/homepageBannerMobile.jpg");*!*/
/*        background-image: url("../assets/img/banner769.jpg");*/
/*        background-size: 100% 100%; !* Ensures the background image covers the entire element *!*/
/*        background-position: center; !* Centers the background image in the element *!*/
/*        background-repeat: no-repeat;*/


/*    }*/
/*    .BannerBg-prod {*/
/*        !*background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/homepage/homepageBannerMobile.jpg");*!*/
/*        background-image: none;*/
/*        background-size: 100% 100%; !* Ensures the background image covers the entire element *!*/

/*    }*/
/*}*/
/*@media only screen and (max-width: 992px) {*/
/*    .BannerBg {*/
/*        !*background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/homepage/homepageBannerMobile.jpg");*!*/
/*        background-image: url("../assets/img/banner769.jpg");*/
/*        background-position: center; !* Centers the background image in the element *!*/
/*        background-repeat: no-repeat;*/
/*        background-size: cover;*/
/*        min-height: auto;*/
/*    }*/

/*    .BannerBg-prod {*/
/*        background-image: url("../assets/img/banner769.jpg");*/
/*        !*background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/homepage/homepageBannerMobile.jpg");*!*/
/*        background-size: 100% 100%; !* Ensures the background image covers the entire element *!*/
/*    }*/
/*}*/

@media only screen and (max-width: 768px) {
    .BannerBg {
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/homepage/homepageBanner768.jpg");
        background-position: center; /* Centers the background image in the element */
        background-repeat: no-repeat;
        background-size: cover;
        min-height: auto;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }

    .BannerBg-prod {
        background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/homepage/homepageBanner768.jpg");
        background-position: center; /* Centers the background image in the element */
        background-repeat: no-repeat;
        background-size: cover;
        min-height: auto;
        width: 100%;
        aspect-ratio: 18 / 13 ;

    }
}

@media only screen and (max-width: 992px) {


    .emailTemplateHeader {
        text-align: center;
        font-size: 28px;
        line-height: 35px;
    }

    .emailCampPanel {
        background: #FFFFFF;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        min-height: 175px;
        height: 100%;

    }

    .email-marketing-block {
        flex: 1 1 30%;
        gap: 20px;
    }

}

@media only screen and (max-width: 576px) {
    .emailMarketingContainer > div {
        gap: 8px;
        padding-top: 30px;
        flex: 1 0 34%;
    }

    .email-marketing-block {
        flex: 1 1 50%;
        gap: 20px;
    }
}
