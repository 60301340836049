.bg-basic {
    background-color: #37B2BF;
}

.bg-pro {
    background-color: #4CD0D4;
}

.bg-business {
    background-color: #7169E5;
}

.bg-enterprise {
    background-color: #81B554;
}

.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
    border: 1px solid #1e53bd;
    border-radius: 10px
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: white;

}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: #1e53bd;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #1e53bd;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
    background-color: white;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.plan-container{
    padding-left: 100px;
    padding-right: 100px;
}


@media (max-width: 1050px) {
    .plan-container{
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (max-width: 992px) {
    .plan-container{
        padding-left: 16px;
        padding-right: 16px;
    }
}