.real-estate-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/realEstate/realEstateBanner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6
}
.real-estate-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/realEstate/realEstateBanner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6
}
.education-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/education/educationBanner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6
}
.education-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/education/educationBanner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6;
}
.dtc-saas-service-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/dtc/dtcBanner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6
}
.dtc-saas-service-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/dtc/dtcBanner.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6;
}
.ecommerce-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/ecommerce/ecommerceBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}
.ecommerce-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/ecommerce/ecommerceBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}

.dtc-saas-service-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/dtc/dtcBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}
.dtc-saas-service-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/dtc/dtcBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}

.education-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/education/educationBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}
.education-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/education/educationBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}

.financial-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/financial/financeBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}
.financial-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/financial/financeBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}

.real-estate-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/realEstate/realEstateBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}
.real-estate-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/realEstate/realEstateBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}


.home-service-banner-bg{
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/homeService/homeServiceBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}
.home-service-banner-bg-prod{
    background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/homeService/homeServiceBanner1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    aspect-ratio: 15 / 6 ;
}

.divider-line{
    border: 1px solid #767676;
    margin-top: 100px;
    margin-bottom: 100px;
}

.button-style {
    color: #FFFFFF;
    background: linear-gradient(180deg, #1671DD 0%, #1053B3 100%);
    font-weight: 500;
    border: none;
    min-height: 44px;
    border-radius: 7px;
    min-width: 165px;
    padding: 12px 24px;
    height: 48px;
    font-size: 18px;
    line-height: 22px;
    opacity: 100%;
    z-index: 1 !important;
}



@media only screen and (max-width: 992px) {
     .divider-line{
        border: 1px solid #767676;
        margin-top: 35px;
        margin-bottom: 35px;
    }
}


@media only screen and (max-width: 768px) {
    .real-estate-banner-bg{
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/realEstate/realEstateBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13;
    }
    .real-estate-banner-bg-prod{
        background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/realEstate/realEstateBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }

    .education-banner-bg{
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/education/educationBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }
    .education-banner-bg-prod{
        background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/education/educationBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }
    .dtc-saas-service-banner-bg{
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/dtc/dtcBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }
    .dtc-saas-service-banner-bg-prod{
        background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/dtc/dtcBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }
    .ecommerce-banner-bg{
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/ecommerce/ecommerceBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }
    .ecommerce-banner-bg-prod{
        background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/ecommerce/ecommerceBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }
    .financial-banner-bg{
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/financial/financeBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }
    .financial-banner-bg-prod{
        background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/financial/financeBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }
    .home-service-banner-bg{
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/homeService/homeServiceBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13 ;
    }
    .home-service-banner-bg-prod{
        background-image: url("https://scuido.s3.ap-south-1.amazonaws.com/public/assets/images/useCases/homeService/homeServiceBanner768.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        aspect-ratio: 18 / 13  ;
    }

}


/*@media only screen and (max-width: 576px) {*/
/*    .real-estate-banner-bg{*/
/*        height: 500px;*/
/*    }*/
/*    .real-estate-banner-bg-prod{*/
/*        height: 500px;*/
/*    }*/

/*    .education-banner-bg{*/
/*        height: 500px;*/
/*    }*/
/*    .education-banner-bg-prod{*/
/*        height: 500px;*/
/*    }*/
/*    .dtc-saas-service-banner-bg{*/
/*        height: 500px;*/
/*    }*/
/*    .dtc-saas-service-banner-bg-prod{*/
/*        height: 500px;*/
/*    }*/
/*    .ecommerce-banner-bg{*/
/*        height: 500px;*/
/*    }*/
/*    .ecommerce-banner-bg-prod{*/
/*        height: 500px;*/
/*    }*/
/*    .financial-banner-bg{*/
/*        height: 500px;*/
/*    }*/
/*    .financial-banner-bg-prod{*/
/*        height: 500px;*/
/*    }*/
/*    .home-service-banner-bg{*/
/*        height: 500px;*/
/*    }*/
/*    .home-service-banner-bg-prod{*/
/*        height: 500px;*/
/*    }*/
/*    .banner-bg-height{*/
/*        height: 500px;*/
/*    }*/
/*}*/


