.block-container {
    padding-right: 100px;
    padding-left: 100px;
}

.innerWindowHeight {
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    /*height:  var(--vh, 100vh) ;*/
}

/*fontSize: isMobileView ? '14px' : "16px"*/


.sign-up-button {
    background-color: #1E53BD;
    color: white;
    font-weight: 500;
    text-transform: initial;
    font-size: 16px;
    border: none;
    border-radius: 7px;
    min-width: 150px;
    font-family: Barlow;
    border-color: #E18600;
    padding: 12px 24px;
    height: 42px;
    border-width: 2px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.footer-sign-up-button {
    background-color: white;
    color: #1E53BD;
    font-weight: 500;
    text-transform: initial;
    font-size: 16px;
    border: none;
    border-radius: 7px;
    min-width: 150px;
    font-family: Barlow;
    border-color: #E18600;
    padding: 12px 24px;
    height: 42px;
    border-width: 2px;
    line-height: 22px;
    align-items: center;
    justify-content: center;
    display: flex;
}
/*fontSize: isMobileView ?*/

/*'14px'*/
/*:*/

/*"16px"*/
/*,*/

.free-demo-button {
    color: #1E53BD;
    font-weight: 500;
    align-items: center;
    display: flex;
    background-color: transparent;
    text-transform: initial;
    font-size: 16px;
    border-radius: 7px;
    font-family: Barlow;
    padding: 12px 24px;
    height: 42px;
    border: 2px solid #1E53BD;
    line-height: 22px;
    min-width: 150px;
}
.footer-free-demo-button {
    color: white;
    font-weight: 500;
    align-items: center;
    display: flex;
    background-color: transparent;
    text-transform: initial;
    font-size: 16px;
    border-radius: 7px;
    min-width: 170px;
    font-family: Barlow;
    padding: 12px 24px;
    height: 42px;
    border: 2px solid white;
    line-height: 22px;
    min-width: 150px;
}

@media only screen and (max-width: 576px) {
    .free-demo-button {
        height: 36px;
    }
    .footer-free-demo-button {
        height: 36px;
    }
    .footer-sign-up-button{
        height: 36px;
    }
    .sign-up-button {
        height: 36px;
    }
    }

.banner-background {
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/dataImport/dataSetupBakground.png");
    height: 440px;
    width: 100%;
    background-size: 100% 100%;
}

.secondary-banner-bg-img {
    background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/dataImport/secondaryBackground_mobile.png");
    width: 100%;
    background-size: 100% 100%;
}

.padding-block-top {
    padding-top: 100px;
}

.padding-block-bottom {
    padding-bottom: 100px;
}

.padding-block-left {
    padding-left: 100px;
}

.padding-block-right {
    padding-right: 100px;
}

.footer-border-light {
    margin-top: 28px;
    margin-bottom: 0;
    border: 1px solid;
    border-color: var(--gray);

}

.padding-banner-header-bottom {
    padding-bottom: 48px;
}

.padding-section-bottom {
    padding-bottom: 150px;
}

.padding-section-vertical {
    padding-top: 150px;
    padding-bottom: 150px;
}

.pt-30 {
    padding-top: 30px;
}

.padding-section-header-bottom {
    padding-bottom: 70px;
}

.paragraph-padding {
    padding-bottom: 40px;
}

.theme-border {
    border: 0.25px solid;
    border-color: var(--primary);
}

.theme-border-dashed {
    border: 0.25px dashed;
    border-color: var(--primary);
}

.cursor {
    cursor: pointer;
}

.newsletter-input > input:focus-visible {
    outline: var(--gray) !important;
}

@media only screen and (max-width: 1200px) {

    .block-container {
        padding-right: 48px;
        padding-left: 48px;
    }
}

@media only screen and (max-width: 992px) {

    .padding-banner-header-bottom {
        padding-bottom: 40px;
    }

    .padding-section-vertical {
        padding-bottom: 90px;
        padding-top: 90px;
    }

    .padding-section-bottom {
        padding-bottom: 90px;
    }

    .padding-section-header-bottom {
        padding-bottom: 35px;
    }

    .paragraph-padding {
        padding-bottom: 20px;
    }

    .padding-block-top {
        padding-top: 50px;
    }

    .padding-block-bottom {
        padding-bottom: 50px;
    }

    .padding-block-left {
        padding-left: 16px;
    }

    .padding-block-right {
        padding-right: 16px;
    }

    .block-container {
        padding-right: 48px;
        padding-left: 48px;
    }
}


@media only screen and (max-width: 768px) {
    .banner-background {
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/dataImport/dataSetupBackground_mobile.png");
        background-size: 100% 100%;
        height: 230px;

    }

    .secondary-banner-bg-img {
        background-image: url("https://dl-uk-dev.s3.ap-south-1.amazonaws.com/public/assets/images/dataImport/secondaryBackground_mobile.png");
        width: 100%;
        background-size: 100% 100%;
    }

    .block-container {
        padding-right: 16px;
        padding-left: 16px;
    }
}

/*new banner css*/
.top-strip-blue {
    height: 10px;
    background: linear-gradient(90deg, #1671DD 0%, #071F6B 100%);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.green-strip {
    height: 10px;
    background: linear-gradient(90deg, #1AA57C 0%, #78EDD0 100%);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.brown-strip {
    height: 10px;
    background: linear-gradient(90deg, #C37C80 0%, #FFBBA7 100%);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.purple-strip {
    height: 10px;
    background: linear-gradient(90deg, #6C59B0 0%, #C79FFB 100%);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.pink-strip {
    height: 10px;
    background: linear-gradient(90deg, #E763A8 0%, #FEA4D7 100%);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.fresh-green-strip {
    height: 10px;
    background: linear-gradient(90deg, #1BB71E 0%, #38FF2C 100%);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.secondary-banner-bg {
    background: linear-gradient(90deg, #1E53BD 0%, #15326D 98%);
}

.pt-60 {
    padding-top: 60px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pb-40 {
    padding-bottom: 40px;
}

.section-py {
    padding-top: 150px;
    padding-bottom: 150px;
}

.page-title {
    font-size: 44px;
}

.section-padding-bottom {
    padding-bottom: 150px;
}

.section-title {
    font-size: 40px;
}

.sub-section-title {
    font-size: 20px;
}

.section-header {
    font-size: 24px;
}

.section-sub-header {
    font-size: 28px;
}

.content {
    font-size: 16px;
}


@media only screen and (max-width: 992px) {
    .section-py {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .page-title {
        font-size: 32px;
    }

    .section-padding-bottom {
        padding-bottom: 70px;
    }

    .section-title {
        font-size: 28px;
    }

    .sub-section-title {
        font-size: 20px;
    }

    .section-header {
        font-size: 20px;
    }

    .section-sub-header {
        font-size: 24px;
    }

    .content {
        font-size: 16px;
    }
}

.section-footer-text {
    font-size: 20px;
}
.pageContainerMargin {
    margin: 0 24px 0 24px !important;
}

.pageContainerMarginRow {
    margin: 0 14px 0 14px !important;
}
