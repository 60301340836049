.theme-inputBox-text-xxs{
    font-size: var(--font-size-xxs);
}
.theme-inputBox-text-xs{
    font-size: var(--font-size-xs);
}
.theme-inputBox-text-sm{
    font-size: var(--font-size-sm);
}
.theme-inputBox-text-md{
    font-size: var(--font-size-md);
}
.theme-inputBox-text-lg{
    font-size: var(--font-size-lg);
}