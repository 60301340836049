* {
    margin: 0;
}

::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #656565;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scuido-label-desktop{
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-xxxl);
    color:var(--primary);
    cursor: pointer;
}

.scuido-label-mobile{
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-lg);
    color:var(--primary);
    cursor: pointer;
}

.body{
    font-size: var(--font-size-sm);
    color: var(--black-text);
    background-color: var(--white);
    font-weight: var(--font-style-normal);
}
#root{
    color: var(--black-text);
    font-size: var(--font-size-sm);
    background-color: var(--white);
    font-weight: var(--font-style-normal);
}

:root {/* Colors: */

    --primary:#1E53BD;
    --secondary:#D2E7FF;
    --tertiary:#FEA133;
    --success:#029C48;
    --warning:#FF9800;
    --error:#D72F2F;
    --info:#333333;
    --white: #FFFFFF;
    --black: #000000;
    --disabled: #CDCDCD;

    --primary-text:#FFFFFF;
    --secondary-text:#333333;
    --tertiary-text:#FFFFFF;
    --success-text:#FFFFFF;
    --warning-text:#FFFFFF;
    --error-text:#FFFFFF;
    --info-text:#333333;
    --white-text: #FFFFFF;
    --black-text: #333333;
    --gray: #666666;
    --disabled-text: #999999;

    /* Font/text values */
    --font-family-barlow: Barlow;
    --font-style-normal: normal;
    --font-weight-bolder: 700;
    --font-weight-bold: 600;
    --font-weight-semibold: 500;
    --font-weight-normal: normal;


    --font-size-xxs: 10px;
    --font-size-xs: 12px;
    --font-size-sm: 16px;
    --font-size-md: 20px;
    --font-size-lg: 24px;
    --font-size-xl: 28px;
    --font-size-xxl: 36px;
    --font-size-xxxl: 40px;
    --character-spacing-0: 0px;
    --line-spacing-18: 18px;
    --line-spacing-20: 20px;
}


.page-container{
    padding-right: 100px;
    padding-left: 100px;
}

.panel {
    background: var(--white) 0 0% no-repeat padding-box;
    box-shadow: 0 0 6px #00000029;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    opacity: 1;
}

/*text heading wise handling*/
.theme-font-page-title{/*h1*/
    font-size: var(--font-size-xxxl);
    line-height: 1.2 !important;
}

.theme-font-section-title{/*h2*/
    font-size: var(--font-size-xxl);
}

.theme-font-section-sub-header{/*h3*/
    font-size: var(--font-size-lg);
}

.theme-font-section-sub-header-lg{/*h3*/
    font-size: var(--font-size-xl);
}

.theme-font-content{/*h4*/
    font-size: var(--font-size-sm);
}

.theme-font-content-md{/*h4*/
    font-size: var(--font-size-md);
    line-height: 1.2;
}

.theme-font-content-lg{/*h4*/
    font-size: var(--font-size-lg);
    line-height: 1.5;
}

.theme-font-content-xs{/*h4*/
    font-size: var(--font-size-xs);
}

.theme-text-white{
    color:var(--white-text)
}

.theme-text-black{
    color:var(--black-text)
}

.theme-text-disabled{
    color:var(--disabled)
}

.theme-text-on-disabled{
    color:var(--disabled-text)
}

.theme-text-error{
    color:var(--error)
}

.theme-text-on-error{
    color:var(--error-text)
}

.theme-text-info{
    color:var(--info)
}

.theme-text-on-info{
    color:var(--info-text)
}

.theme-text-warning{
    color:var(--warning)
}

.theme-text-on-warning{
    color:var(--warning-text)
}

.theme-text-success{
    color:var(--success)
}

.theme-text-on-success{
    color:var(--success-text)
}

.theme-text-tertiary{
    color:var(--tertiary)
}

.theme-text-on-tertiary-bg{
    color:var(--tertiary-text)
}

.theme-text-secondary{
    color:var(--secondary)
}

.theme-text-on-secondary-bg{
    color:var(--secondary-text)
}

.theme-text-primary{
    color:var(--primary)
}

.theme-text-on-primary-bg{
    color:var(--primary-text)
}
.theme-text-justify{
    text-align: justify;
}

.theme-bg-primary{
    background-color: var(--primary);
}

.theme-bg-secondary{
    background-color: var(--secondary);
}

.theme-bg-tertiary{
    background-color: var(--tertiary);
}

.theme-bg-success{
    background-color: var(--success);
}

.theme-bg-warning{
    background-color: var(--warning);
}

.theme-bg-error{
    background-color: var(--error);
}

.theme-bg-disabled{
    background-color: var(--disabled);
}

.theme-bg-white{
    background-color: var(--white);
}

.theme-bg-black{
    background-color: var(--black);
}


.theme-font-normal{
    font-weight: var(--font-weight-normal);
}

.theme-font-semiBold{
    font-weight: var(--font-weight-semibold);
}

.theme-font-bold{
    font-weight: var(--font-weight-bold);
}

.theme-font-bolder{
    font-weight: var(--font-weight-bolder);
}

/*for tablet view only*/
@media only screen and (max-width: 992px) {
    .page-container{
        padding-right: 50px;
        padding-left: 50px;
    }
    .theme-font-page-title{/*h1*/
        font-size: var(--font-size-xl);
    }

    .theme-font-section-title{/*h2*/
        font-size: var(--font-size-lg);
    }

    .theme-font-section-sub-header{/*h3*/
        font-size: var(--font-size-lg);
    }

    .theme-font-content{/*h4*/
        font-size: var(--font-size-sm);
    }

    .theme-font-content-lg{/*h4*/
        font-size: var(--font-size-sm);
        line-height: 1.5;
    }

    .theme-font-content-md{/*h4*/
        font-size: var(--font-size-sm);
        line-height: 1.2;
    }
}

/*for mobiles only*/
@media only screen and (max-width: 768px) {

    .theme-font-page-title{
        font-size: var(--font-size-xxl);
    }

    .theme-font-section-title{
        font-size: var(--font-size-lg);
    }

    .theme-font-section-sub-header{
        font-size: var(--font-size-md);
    }

    .theme-font-content{/*h4*/
         font-size: var(--font-size-sm);
     }

    .theme-font-content-lg{/*h4*/
        font-size: var(--font-size-sm);
        line-height: 1.5;
    }

    .theme-font-content-md{/*h4*/
        font-size: var(--font-size-sm);
        line-height: 1.2;
    }

    .page-container{
        padding-right: 16px;
        padding-left: 16px;
    }
}

.font-size-md{
    font-size: var(--font-size-md);

}

a{
    text-decoration: unset !important;
}

h1{
    margin-bottom: 0;
}
h2{
    margin-bottom: 0;
}

h3{
    margin-bottom: 0;
}

h4{
    margin-bottom: 0;
}
