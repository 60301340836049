.bottom-border-line{
    display: flex;
    justify-content: center;
    border:0.5px solid var(--primary);
    margin-right: 270px;
    margin-left: 270px;
    margin-top: 100px;
    margin-bottom: 100px;
}

@media only screen and (max-width: 992px) {
    .bottom-border-line{
        display: flex;
        justify-content: center;
        border: 1px solid var(--primary);
        margin: 50px 56px;
    }
}
